.eventticket--fullscreen-svg {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 118px);
    background: rgba(255,255,255,.85);
    margin-bottom: 120px;
}

.eventticket--fullscreen-svg #svgMap {
    width: 100%;
    height: 100%;
}

.eventticket--fullscreen-btn {
    color: #1636a8;
    background-color: #fff;
    border: 4px solid #1636a8;
    border-radius: 10px;
    display: none;
    position: absolute;
    bottom: 70px;
    padding: 4px;
    z-index: 5;
    right: 20px;
}
.eventticket--fullscreen-btn i {
    font-size: 30px;
}
