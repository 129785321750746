.seat-selection {
    margin: 10px auto;
    overflow: auto
}

.seat-area {
    white-space: nowrap
}

.seat-selection .seat-area {
    text-align: center;
    overflow: auto
}

.seat-selection .seat-area .seat-area-desc {
    line-height: 35px
}

.seat-selection .seat-row-seat {
    display: inline-block;
    border: 1px solid transparent;
    text-align: center;
    margin: 2px;
    vertical-align: -webkit-baseline-middle;
    -webkit-border-radius: 2px;
    border-radius: 2px
}

.seat-selection .seat-row-seat span {
    display: block;
    width: 14px;
    height: 14px;
    margin: 1px 0 0 1px;
    background: #b9b9b9;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    border: 1px solid #fff
}

.seat-selection .seat-row-seat.can-select {
    cursor: pointer
}

.seat-selection .seat-row-seat.can-select span {
    font-size: .8em
}

.seat-selection .seat-row-seat.can-select:hover span {
    background-color: #dcdcdc
}

.seat-selection .row-indicator {
    margin-right: 10px !important;
    color: green
}

.seat-selection .movie-screen {
    width: 100%;
    text-align: center;
    margin: 60px 0 15px;
    font-size: .8em;
    color: #626573;
    -webkit-box-shadow: inset 0 7px 12px 0 #9c9c9c;
    box-shadow: inset 0 7px 12px 0 #9c9c9c
}

.seat-selection .seat-proccess-panel {
    padding: 10px;
    border-top: 3px solid #009486;
    text-align: right
}

.seat-selection .layout-action-btn {
    padding: 5px 20px;
    color: #fff;
    cursor: pointer;
    background-color: burlywood;
    border: 1px solid #e7e7e7;
    -webkit-box-shadow: 0 2px 5px 0 #2b2b2b;
    box-shadow: 0 2px 5px 0 #2b2b2b;
    -webkit-border-radius: 2px;
    border-radius: 2px
}

.seat-selection .layout-action-btn[disabled] {
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #b1b1b1 !important;
    border-color: #828282 !important;
    cursor: not-allowed
}

.seat-selection .layout-action-btn.layout-btn-done {
    border: 1px solid #7ca07d;
    background-color: #228b22
}

#bookingMap {
    max-width: 360px;
    margin: auto
}

#bookingMap circle.selected {
    fill: #f00
}

@media screen and (max-width: 720px) {
    #layout-btn-done {
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        z-index: 1000
    }
}

.booking-sector__ticket-quantity-selection {
    z-index: 100;
    margin-top: 20px
}

.booking-sector__ticket-quantity-selection select.form-control {
    max-width: 114px
}

.booking-map__full .booking-sector-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.booking-map__full .booking-sector {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    display: grid
}

.booking-map__full .booking-sector:hover .hover-message {
    display: block
}

.booking-map__full .booking-sector .back-label, .booking-map__full .booking-sector .hover-message {
    display: none;
    background-color: rgba(0, 0, 0, .5);
    padding: 10px;
    color: #fff
}

.booking-map__full .booking-sector.hide-label-show-back .back-label {
    display: block
}

.booking-map__full .booking-sector.hide-label-show-back .hover-message {
    display: none
}

.booking-map__full .booking-sector.hide-bg {
    background-image: none
}

.booking-map__full .stage {
    /*background-image: url(../images/mapa_teatro-stage-01.png);*/
    width: 100%;
    height: 76px;
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom
}

.booking-map__full .super-vip {
    /*background-image: url(../images/mapa_teatro-super-vip-02.png);*/
    height: 68px;
    width: 100%;
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top
}

.booking-map__full .left-side {
    /*background-image: url(../images/mapa_teatro-left-side-03.png);*/
    height: 618px;
    width: 160px;
    min-width: 160px;
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top
}

.booking-map__full .left-vip, .booking-map__full .right-vip {
    /*background-image: url(../images/mapa_teatro-side-vip-04.png);*/
    height: 286px;
    width: 210px;
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom
}

.booking-map__full .left-side-central, .booking-map__full .right-side-central {
    /*background-image: url(../images/mapa_teatro-central-side-05.png);*/
    height: 226px;
    width: 210px;
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top
}

.booking-map__full .central-vip {
    /*background-image: url(../images/mapa_teatro-center-vip-06.png);*/
    height: 286px;
    width: 260px;
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom
}

.booking-map__full .special-central {
    /*background-image: url(../images/mapa_teatro-center-central-07.png);*/
    height: 146px;
    width: 260px;
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top
}

.booking-map__full .right-side {
    /*background-image: url(../images/mapa_teatro-right-side-08.png);*/
    height: 618px;
    width: 160px;
    min-width: 160px;
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top
}

.booking-map__full-view {
    width: 100%;
    height: 400px;
    /*background-image: url(../images/mapa_teatro.jpg);*/
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block
}

.booking-map-container__reference-panel {
    margin-top: 20px;
    font-size: 12px
}

@media screen and (min-width: 720px) {
    .booking-map-container__reference-panel {
        font-size: 15px
    }

    .booking-map-container__reference-panel .booking-map-container__reference-panel-cell {
        width: auto !important
    }
}

.booking-map-container__reference-panel .booking-map-container__reference-panel-cell {
    display: inline-block;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 33%;
    padding: 5px
}

.booking-map-container__reference-panel .reference-available-seat, .booking-map-container__reference-panel .reference-user-seat, .booking-map-container__reference-panel .reference-unavailable-seat {
    width: 16px;
    height: 26px;
    display: inline-block;
    float: left;
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 5px
}

.booking-map-container__reference-panel .reference-user-seat svg .st0 {
    fill: #f00
}

.booking-map-container__reference-panel .reference-unavailable-seat {
    -webkit-filter: grayscale(1);
    filter: grayscale(1)
}

.movieLayoutContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    margin: auto
}

.movieLayoutContainer .seat-selection .movie-screen {
    margin-top: 34px;
    padding: 20px
}

.movieLayoutContainer .seat-selection .seat-row-seat.can-select.seat-yes.seat-disability, .movieLayoutContainer .seat-selection .seat-row-seat.can-select.seat-disability {
    /*background-image: url(../images/chair-disability.png);*/
    -webkit-background-size: contain;
    background-size: contain
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.25), only screen and (min-device-pixel-ratio: 1.25), only screen and (min-resolution: 1.25dppx) {
    .movieLayoutContainer .seat-selection .seat-row-seat.can-select.seat-yes.seat-disability, .movieLayoutContainer .seat-selection .seat-row-seat.can-select.seat-disability {
        /*background-image: url(../images/chair-disability@2x.png)*/
    }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2.25), only screen and (min-device-pixel-ratio: 2.25), only screen and (min-resolution: 2.25dppx) {
    .movieLayoutContainer .seat-selection .seat-row-seat.can-select.seat-yes.seat-disability, .movieLayoutContainer .seat-selection .seat-row-seat.can-select.seat-disability {
        /*background-image: url(../images/chair-disability@3x.png)*/
    }
}

.movieLayoutContainer .seat-selection .seat-row-seat {
    margin: 0;
    border: none
}

.movieLayoutContainer .seat-selection .seat-row-seat.seat-yes svg .st0 {
    fill: #949494
}

.movieLayoutContainer .seat-selection .seat-row-seat.can-select span, .movieLayoutContainer .seat-selection .seat-row-seat span {
    opacity: .5;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none
}

.movieLayoutContainer .seat-selection .seat-row-seat.current-selected.can-select svg .st0 {
    fill: #f00 !important
}

.movieLayoutContainer .seat-selection .seat-row-seat.current-selected.can-select.seat-disability span {
    /*background-image: url(../images/chair-disability-selected.png);*/
    width: 100%;
    height: 100%;
    position: relative;
    left: -2px;
    top: -1px;
    -webkit-background-size: contain;
    background-size: contain
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.25), only screen and (min-device-pixel-ratio: 1.25), only screen and (min-resolution: 1.25dppx) {
    .movieLayoutContainer .seat-selection .seat-row-seat.current-selected.can-select.seat-disability span {
        /*background-image: url(../images/chair-disability-selected@2x.png)*/
    }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2.25), only screen and (min-device-pixel-ratio: 2.25), only screen and (min-resolution: 2.25dppx) {
    .movieLayoutContainer .seat-selection .seat-row-seat.current-selected.can-select.seat-disability span {
        /*background-image: url(../images/chair-disability-selected@3x.png)*/
    }
}

.movieLayoutContainer .seat-selection .seat-proccess-panel {
    padding: 10px;
    border-top: none;
    text-align: center;
    margin-top: 10px;
    min-width: 180px
}

@media screen and (max-width: 720px) {
    .movieLayoutContainer .seat-selection .seat-proccess-panel {
        width: 95%;
        width: 90vw;
        position: absolute;
        margin: auto;
        left: 5%;
        left: 5vw
    }
}

.movieLayoutContainer .seat-selection .row-indicator, .movieLayoutContainer .seat-selection .col-indicator {
    text-transform: uppercase;
    background-image: none !important;
    font-size: 12px
}

.movieLayoutContainer .seat-area-row {
    padding: 0;
    margin: .5px auto
}

.movieLayoutContainer .seat-area.leftSide, .movieLayoutContainer .seat-area.rightSide {
    width: 130%;
    padding-right: 24px;
    right: 22px;
    top: -6px;
    position: relative;
    padding-top: 8px
}

.movieLayoutContainer .seat-area.leftSide .seat-row-seat, .movieLayoutContainer .seat-area.rightSide .seat-row-seat {
    bottom: 6px;
    position: relative;
    margin: 1px
}

.movieLayoutContainer .seat-area.leftSide .seat-area-row, .movieLayoutContainer .seat-area.rightSide .seat-area-row {
    padding-right: 0;
    height: 18px
}

.movieLayoutContainer .rightSideRow {
    width: 24px;
    height: 18px
}

.reference-labels__title {
    margin-top: 40px
}

.reference-labels {
    padding: 5px;
    margin: 5px
}

.reference-labels.yellow {
    background-color: #f5e3ab
}

.reference-labels.pink {
    background-color: #f5b3c4
}

.reference-labels.green {
    background-color: #a7d2b8
}

.reference-labels.blue {
    background-color: #c8d8e9
}

body {
    padding-top: 50px
}

.sub-header {
    padding-bottom: 10px;
    border-bottom: 1px solid #eee
}

.navbar-fixed-top {
    border: 0
}

.sidebar {
    display: none
}

@media (min-width: 768px) {
    .sidebar {
        position: fixed;
        top: 51px;
        bottom: 0;
        left: 0;
        z-index: 1000;
        display: block;
        padding: 20px;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: #f5f5f5;
        border-right: 1px solid #eee
    }
}

.nav-sidebar {
    margin-right: -21px;
    margin-bottom: 20px;
    margin-left: -20px
}

.nav-sidebar > li > a {
    padding-right: 20px;
    padding-left: 20px
}

.nav-sidebar > .active > a, .nav-sidebar > .active > a:hover, .nav-sidebar > .active > a:focus {
    color: #fff;
    background-color: #428bca
}

.main {
    padding: 20px
}

@media (min-width: 768px) {
    .main {
        padding-right: 40px;
        padding-left: 40px
    }
}

.main .page-header {
    margin-top: 0
}

.all-events-profiles {
    padding: 0
}

.all-events-profiles li {
    list-style: none
}

.all-events-profiles .all-events-profiles__box {
    max-width: 240px;
    max-height: 180px;
    display: inline-block;
    float: left;
    width: 240px;
    height: 180px;
    background: #000;
    margin: 5px;
    color: #fff;
    -webkit-background-size: cover;
    background-size: cover;
    cursor: pointer
}

.all-events-profiles .all-events-profiles__box-event-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    height: 100%;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-line-pack: end;
    align-content: flex-end;
    padding: 5px
}

.all-events-profiles .all-events-profiles__box-event-item, .all-events-profiles .all-events-profiles__box-event-name {
    display: block;
    width: 100%;
    padding: 1px 10px;
    text-align: right;
    background-color: rgba(0, 0, 0, .7)
}

.all-events-profiles .all-events-profiles__box-event-name {
    font-weight: 700;
    padding: 5px 10px
}

.event-profile-map {
    width: 100%;
    height: 250px;
    margin: auto
}

@media screen and (min-width: 720px) {
    .event-profile-map {
        margin: 10px
    }
}

.event-profile-data .event-profile-data__first-child {
    margin-top: 10px
}

.event-profile-data .event-profile-data__last-child {
    margin-bottom: 10px
}

.event-profile-data__description {
    margin-top: -3.5rem
}

.event-profile-data__event-name {
    font-size: 15pt;
    text-align: center;
    display: inline-block
}

.event-profile-data__event-mobile-card-wrapper {
    border: 1px solid #707070;
    margin: 15px;
    padding: 0
}

.event-profile-data__event-mobile-card-wrapper__map {
    border: 1px solid #707070;
    margin: 15px;
    padding: 0
}

.event-profile-data__event-mobile-card-wrapper .event-profile-data__event-name, .event-profile-data__event-mobile-card-wrapper .event-profile-data__event-description {
    padding: 15px
}

.event-profile-data__event-description {
    text-align: center
}

.event-profile-data__event-description ul {
    text-align: left
}

.event-profile-data__event-description a[href^=mailto] {
    overflow-wrap: break-word
}

.event-profile-data__event-name {
    text-align: center
}

.event-profile-data__event-description-label {
    background-color: #f00;
    padding: 15px;
    width: 100%;
    color: #fff;
    font-weight: 400;
    font-size: 14pt
}

@media screen and (min-width: 720px) {
    .event-profile-data__event-mobile-card-wrapper {
        margin: 0;
        border: none
    }

    .event-profile-data__event-mobile-card-wrapper__map {
        margin: 0;
        border: none;
        width: 100%
    }

    .event-profile-data__event-name {
        text-align: left;
        font-size: 39px;
        margin-bottom: 20px
    }

    .event-profile-data__event-description {
        text-align: left
    }
}

.event-profile__subheader {
    background-color: #f00;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px
}

.event-profile__subheader h1 {
    font-size: 14pt;
    color: #fff;
    line-height: 1.2
}

@media screen and (min-width: 720px) {
    .event-profile__subheader {
        background-color: transparent;
        width: 100%;
        margin: 0 auto;
        padding: 15px
    }

    .event-profile__subheader h1 {
        font-size: 39px;
        color: inherit
    }

    .event-profile__subheader-desktop {
        background-color: #f00;
        width: 100%;
        margin: 0 auto;
        padding: 0 15px;
        color: #fff
    }

    .event-profile__subheader-desktop h1 {
        font-size: 17px
    }
}

.events-seat-map-reference-desktop-link-wrapper {
    cursor: pointer;
    padding: 20px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
    display: inline-block;
    display: table;
    min-width: 30%;
    min-height: 25vh
}

.events-seat-map-reference-desktop-link-wrapper .seat-map-reference-image {
    width: 100%;
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 10px;
    display: table-cell;
    vertical-align: bottom
}

.events-seat-map-reference-desktop-link-wrapper .seat-map-reference-image-text {
    background-color: #fff;
    padding: 5px 3px
}

.events-seat-map-reference-desktop-link-wrapper span {
    vertical-align: bottom;
    display: table-cell;
    text-align: center
}

.events-seat-map-theater {
    overflow: auto
}

.events-seat-map-theater img {
    width: 100%
}

@media screen and (min-width: 720px) {
    .events-seat-map-theater {
        display: inline-block;
        width: 79%
    }
}

.events-seat-map-reference {
    text-align: left
}

.events-seat-map-reference img {
    width: 100%
}

@media screen and (min-width: 720px) {
    .events-seat-map-reference {
        display: inline-block;
        width: 20%
    }
}

.eventticket-card-form .form-control, .eventticket-card-form .form-row {
    margin: 10px auto
}

.event-loading {
    padding: 100px 30px;
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #fff;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}