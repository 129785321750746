.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.show-inline {
  display: inline-block !important;
}

.main-wrapper {
  padding-bottom: 50px;
}

.w-100 {
  width: 100%;
}

.mdl-layout__header {
  width: 100%;
}

.rodal, .rodal-mask {
  z-index: 10000 !important;
}

.rodal-dialog {
  text-align: center;
  max-width: 420px;
  z-index: 10000 !important;
}

.rodal-title,
.rodal-body {
  padding: 10px 0;
}
.rodal-footer {
  padding: 20px 0 0;
  display: flex;
  justify-content: space-around;
}

/* EVENTTICKET */

.eventticket-datetime-container {
  font-size: 18px;
}
.eventticket-datetime-container .dayName,
.eventticket-datetime-container .month {
  margin: 0 0.5rem;
}

.eventticket--profile-performances .eventticket-datetime-container {
  display: inline-block;
  margin: 0 0.5rem;
}

.tickets-list-table .eventticket-datetime-container {
  display: inline-block;
  margin: 0 0.5rem;
}

/* CHECKOUT MAP */
.eventticket-cho-map svg {
  width: 100%;
  height: 200px;
}
.eventticket-cho-map circle {
  cursor: pointer;
}
.eventticket-cho-map circle:hover {
  opacity: 0.5;
  filter: hue-rotate(90deg);
}
.eventticket-cho-map circle.selected {
  filter: hue-rotate(90deg);
  /*fill: deeppink;
  opacity: 0.5;*/
  fill: yellow;
  opacity: 0.8;
}
.eventticket-cho-map circle[frontendfiltered="1"],
.eventticket-cho-map circle[frontendfiltered="true"] {
  fill: #ccc;
}
.eventticket-cho-map circle[seatdisabled="1"],
.eventticket-cho-map circle[seatdisabled="true"] {
  /*fill: #ccccccaa;*/
}

@media screen and (min-width: 640px) {
  .eventticket-cho-map svg {
    width: 100%;
    height: 600px;
  }
}

.eventticket-reference-color {
  display: inline-block;
  width: 25px;
  height: 25px;
  vertical-align: middle;
  margin-right: 10px;
}

/* SVG PAN ZOOM */
#svg-pan-zoom-controls {
  /*transform: scale(1);*/
}

/* STRIPE FORM */
.eventticket-card-form {
  max-width: 720px;
  margin: auto;
  padding: 20px;
  text-align: center;
}

.eventticket-card-form b {
  font-size: 12px;
  color: #666;
  margin-top: 40px;
  display: inline-block;
}

.eventticket-card-form img {
  width: 70%;
  margin: 6px 0 20px;
  max-width: 200px; /* only stripe logos by now */
}

.eventticket-card-form---input {
  border: 1px solid #ccc;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;

  margin-bottom: 20px;
}

.eventticket-card-form--info-label {
  margin-bottom: 20px;
}

.eventticket--mobile-zoom-in {
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-image: radial-gradient(#eee, transparent 80%);
  color: #000;
  font-weight: bold;
}

.eventticket--mobile-zoom-in span {
  width: 100%;
  display: inline-block;
  top: 45%;
  position: relative;
  text-align: center;
}

.eventticket--mobile-zoom-out {
  color: #1636a8;
  background-color: #fff;
  border: 4px solid #1636a8;
  border-radius: 10px;
  display: none;
  position: relative;
  bottom: 70px;
  padding: 4px;
  z-index: 1;
}

.eventticket--mobile-zoom-out .fa-search-minus {
  font-size: 30px;
}

.eventticket-cho-cart-review {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
  padding: 0 20px 10px;
  border-top: 1px solid #ccc;
  background: #333;
  border-top: 1px solid #ccc;
  color: #fff;
}

.eventticket-cho-cart-review-toggle {
  padding: 20px 10px 0 0;
  margin: 0 10px 10px 10px;
  cursor: pointer;
}

.eventticket-cho-cart-review-toggle:hover {
  /*background-color: #eee;*/
}

.eventticket-cho-cart-review--ticket {
  display: inline-block;
}

/* MOBILE **/
@media screen and (max-width: 420px) {
  .all-events-profiles .all-events-profiles__box {
    max-width: initial;
    width: 100%;
  }
}

.primary-color {
  color: #f00;
}

/* BOOTSTRAP 4 OVERWRITES*/
.p-0 {
  padding: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

/* BOOTSTRAP 3 HELPERS CUSTOM */
.padding-xs { padding: .25em; }
.padding-sm { padding: .5em; }
.padding-md { padding: 1em; }
.padding-lg { padding: 1.5em; }
.padding-xl { padding: 3em; }

.padding-x-xs { padding: .25em 0; }
.padding-x-sm { padding: .5em 0; }
.padding-x-md { padding: 1em 0; }
.padding-x-lg { padding: 1.5em 0; }
.padding-x-xl { padding: 3em 0; }

.padding-y-xs { padding: 0 .25em; }
.padding-y-sm { padding: 0 .5em; }
.padding-y-md { padding: 0 1em; }
.padding-y-lg { padding: 0 1.5em; }
.padding-y-xl { padding: 0 3em; }

.padding-top-xs { padding-top: .25em; }
.padding-top-sm { padding-top: .5em; }
.padding-top-md { padding-top: 1em; }
.padding-top-lg { padding-top: 1.5em; }
.padding-top-xl { padding-top: 3em; }

.padding-right-xs { padding-right: .25em; }
.padding-right-sm { padding-right: .5em; }
.padding-right-md { padding-right: 1em; }
.padding-right-lg { padding-right: 1.5em; }
.padding-right-xl { padding-right: 3em; }

.padding-bottom-xs { padding-bottom: .25em; }
.padding-bottom-sm { padding-bottom: .5em; }
.padding-bottom-md { padding-bottom: 1em; }
.padding-bottom-lg { padding-bottom: 1.5em; }
.padding-bottom-xl { padding-bottom: 3em; }

.padding-left-xs { padding-left: .25em; }
.padding-left-sm { padding-left: .5em; }
.padding-left-md { padding-left: 1em; }
.padding-left-lg { padding-left: 1.5em; }
.padding-left-xl { padding-left: 3em; }

.margin-xs { margin: .25em; }
.margin-sm { margin: .5em; }
.margin-md { margin: 1em; }
.margin-lg { margin: 1.5em; }
.margin-xl { margin: 3em; }

.margin-x-xs { margin: .25em 0; }
.margin-x-sm { margin: .5em 0; }
.margin-x-md { margin: 1em 0; }
.margin-x-lg { margin: 1.5em 0; }
.margin-x-xl { margin: 3em 0; }

.margin-y-xs { margin: 0 .25em; }
.margin-y-sm { margin: 0 .5em; }
.margin-y-md { margin: 0 1em; }
.margin-y-lg { margin: 0 1.5em; }
.margin-y-xl { margin: 0 3em; }

.margin-top-xs { margin-top: .25em; }
.margin-top-sm { margin-top: .5em; }
.margin-top-md { margin-top: 1em; }
.margin-top-lg { margin-top: 1.5em; }
.margin-top-xl { margin-top: 3em; }

.margin-right-xs { margin-right: .25em; }
.margin-right-sm { margin-right: .5em; }
.margin-right-md { margin-right: 1em; }
.margin-right-lg { margin-right: 1.5em; }
.margin-right-xl { margin-right: 3em; }

.margin-bottom-xs { margin-bottom: .25em; }
.margin-bottom-sm { margin-bottom: .5em; }
.margin-bottom-md { margin-bottom: 1em; }
.margin-bottom-lg { margin-bottom: 1.5em; }
.margin-bottom-xl { margin-bottom: 3em; }

.margin-left-xs { margin-left: .25em; }
.margin-left-sm { margin-left: .5em; }
.margin-left-md { margin-left: 1em; }
.margin-left-lg { margin-left: 1.5em; }
.margin-left-xl { margin-left: 3em; }
